import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  Button,
  Grid,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from "@mui/material";
import { BarChart } from "@mui/x-charts";
import {
  // BarChart,
  Bar,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
  TooltipProps,
} from "recharts";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  exportToExcelOther,
  GetArray,
  GetObject,
  WithCommas,
} from "../../../../utils/utils";
import MiniSpinner from "../../../reusableComponents/miniSpinner/miniSpinner";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Grid
        style={{
          border: "1px solid",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography>{label}</Typography>
        <Divider />
        {payload.map((element: any) => (
          <Typography sx={{ color: element.color }}>{`${
            element?.name[0]?.toUpperCase() + element?.name?.slice(1)
          } : ${WithCommas(element.value)}`}</Typography>
        ))}
      </Grid>
    );
  }
  return null;
};

const CustomTooltipPercentage = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Grid
        style={{
          border: "1px solid",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography>{label}</Typography>
        <Divider />
        {payload.map((element: any) => (
          <Typography sx={{ color: element.color }}>{`${
            element?.name[0]?.toUpperCase() + element?.name?.slice(1)
          } : ${WithCommas(element.value)} %`}</Typography>
        ))}
      </Grid>
    );
  }
  return null;
};

const CustomTooltipRevenue = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Grid
        style={{
          border: "1px solid",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography>{label}</Typography>
        <Divider />
        {payload.map((element: any) => (
          <Typography sx={{ color: element.color }}>{`${
            element?.name[0]?.toUpperCase() + element?.name?.slice(1)
          } : $ ${WithCommas(element.value)}`}</Typography>
        ))}
      </Grid>
    );
  }
  return null;
};

const Transactions = () => {
  const {
    selectedBranch,
    selectedDepartment,
    purchaseTagRawData,
    purchaseFreqRawData,
    branchTransactionsRawData,
    productDistributionRawData,
    industryRawData,
    industryAllRawData,
    balanceRawData,
    purchaseDistributionLoading,
    industryByRevenueLoading,
    forecastRevenuePlanLoading,
    prospectleadDetailRawData,
  } = useAppSelector((state: any) => state.portalManagement);

  const [
    transactionsLastPurchaseChartAccountPercent,
    setTransactionsLastPurchaseChartAccountPercent,
  ] = useState([]);
  const [
    transactionLastPurchaseChartRevenuePercent,
    setTransactionLastPurchaseChartRevenuePercent,
  ] = useState([]);
  const [
    transactionsLastPurchaseChartlabel,
    setTransactionsLastPurchaseChartlabel,
  ] = useState([]);
  const [
    transactionsLastPurchaseCustomer,
    setTransactionsLastPurchaseCustomer,
  ] = useState("");
  const [transactionLastPurchaseRevenue, setTransactionLastPurchaseRevenue] =
    useState("");
  const [
    transactionsPurchaseFreqChartAccountPercent,
    setTransactionsPurchaseFreqChartAccountPercent,
  ] = useState([]);
  const [
    transactionPurchaseFreqChartRevenuePercent,
    setTransactionPurchaseFreqChartRevenuePercent,
  ] = useState([]);
  const [
    transactionsPurchaseFreqChartlabel,
    setTransactionsPurchaseFreqChartlabel,
  ] = useState([]);
  const [
    transactionsPurchaseFreqCustomer,
    setTransactionsPurchaseFreqCustomer,
  ] = useState("");
  const [transactionPurchaseFreqRevenue, setTransactionPurchaseFreqRevenue] =
    useState("");
  const [
    transactionPurchaseBranchTranChartCustFreq,
    setTransactionPurchaseBranchTranChartCustFreq,
  ] = useState([]);
  const [
    transactionPurchaseBranchTranChartRevFreq,
    setTransactionPurchaseBranchTranChartRevFreq,
  ] = useState([]);
  const [
    transactionPurchaseBranchTranChartOrdFreq,
    setTransactionPurchaseBranchTranChartOrdFreq,
  ] = useState([]);
  const [
    transactionPurchaseBranchTranChartLabel,
    setTransactionPurchaseBranchTranChartLabel,
  ] = useState([]);
  const [
    transactionPurchaseProductTranChartRevenue,
    setTransactionPurchaseProductTranChartRevenue,
  ] = useState([]);
  const [
    transactionPurchaseProductTranChartOrdFreq,
    setTransactionPurchaseProductTranChartOrdFreq,
  ] = useState([]);
  const [
    transactionPurchaseProductTranChartLabel,
    setTransactionPurchaseProductTranChartLabel,
  ] = useState([]);
  const [
    transactionPurchaseIndustryChartRevenue,
    setTransactionPurchaseIndustryChartRevenue,
  ] = useState([]);
  const [
    transactionPurchaseIndustryChartAccount,
    setTransactionPurchaseIndustryChartAccount,
  ] = useState([]);
  const [
    transactionPurchaseIndustryChartIndustryCount,
    setTransactionPurchaseIndustryChartIndustryCount,
  ] = useState([]);
  const [
    transactionPurchaseIndustryChartLabel,
    setTransactionPurchaseIndustryChartLabel,
  ] = useState([]);
  const [
    transactionPurchaseIndustryMarketShare,
    setTransactionPurchaseIndustryMarketShare,
  ] = useState("");
  const [
    transactionPurchaseIndustryTotPotRev,
    setTransactionPurchaseIndustryTotPotRev,
  ] = useState("");
  const [
    transactionPurchaseIndustryMarkSharInc,
    setTransactionPurchaseIndustryMarkSharInc,
  ] = useState("");

  const [transactionPurchaseRevMonData, setTransactionPurchaseRevMonData] =
    useState<any[]>([]);

  const [
    transactionPurchaseRevMonTableRow,
    setTransactionPurchaseRevMonTableRow,
  ] = useState<any[]>([]);

  const [transactionPurchaseGrosMargData, setTransactionPurchaseGrosMargData] =
    useState<any[]>([]);

  const [
    transactionPurchaseGrosMargTableRow,
    setTransactionPurchaseGrosMargTableRow,
  ] = useState<any[]>([]);

  const [transactionPurchaseRolRevData, setTransactionPurchaseRolRevData] =
    useState<any[]>([]);

  const [transactionPurchaseRolGrosData, setTransactionPurchaseRolGrosData] =
    useState<any[]>([]);

  const [
    transactionPurchaseRolRevTableRow,
    setTransactionPurchaseRolRevTableRow,
  ] = useState<any[]>([]);

  const [
    transactionPurchaseRolGrosTableRow,
    setTransactionPurchaseRolGrosTableRow,
  ] = useState<any[]>([]);

  const [activePurchaseDistribution, setActivePurchaseDistribution] =
    useState("BRANCH");
  const handlePurchaseDistributionNav = (value: string) => {
    setActivePurchaseDistribution(value);
  };
  const [activeRevenueMonth, setActiveRevenueMonth] = useState("REVENUE");
  const handleRevenueMonthNav = (value: string) => {
    setActiveRevenueMonth(value);
  };

  const growthForecastFilterData = () => {
    if (purchaseTagRawData) {
      const purDistLpTFilter = GetObject(
        GetArray(purchaseTagRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(purDistLpTFilter)?.length > 0) {
        setTransactionsLastPurchaseChartAccountPercent(
          purDistLpTFilter?.ACCOUNT_PERCENT
            ? purDistLpTFilter?.ACCOUNT_PERCENT
            : []
        );
        setTransactionLastPurchaseChartRevenuePercent(
          purDistLpTFilter?.REVENUE_PERCENT
            ? purDistLpTFilter?.REVENUE_PERCENT
            : []
        );
        setTransactionsLastPurchaseChartlabel(
          purDistLpTFilter?.LAST_PURCHASE_TAG
            ? purDistLpTFilter?.LAST_PURCHASE_TAG
            : []
        );

        setTransactionsLastPurchaseCustomer(
          purDistLpTFilter?.ACCOUNT_PERCENT &&
            purDistLpTFilter?.ACCOUNT_PERCENT?.length > 0
            ? purDistLpTFilter?.ACCOUNT_PERCENT[0]
            : ""
        );
        setTransactionLastPurchaseRevenue(
          purDistLpTFilter?.REVENUE_PERCENT &&
            purDistLpTFilter?.REVENUE_PERCENT?.length > 0
            ? purDistLpTFilter?.REVENUE_PERCENT[0]
            : ""
        );
      } else {
        setTransactionsLastPurchaseChartAccountPercent([]);
        setTransactionLastPurchaseChartRevenuePercent([]);
        setTransactionsLastPurchaseChartlabel([]);
      }
    }

    if (purchaseFreqRawData) {
      const purDistPFFilter = GetObject(
        GetArray(purchaseFreqRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(purDistPFFilter)?.length > 0) {
        setTransactionsPurchaseFreqChartAccountPercent(
          purDistPFFilter?.ACCOUNT_PERCENT
            ? purDistPFFilter?.ACCOUNT_PERCENT
            : []
        );
        setTransactionPurchaseFreqChartRevenuePercent(
          purDistPFFilter?.REVENUE_PERCENT
            ? purDistPFFilter?.REVENUE_PERCENT
            : []
        );
        setTransactionsPurchaseFreqChartlabel(
          purDistPFFilter?.PURCHASE_FREQUENCY
            ? purDistPFFilter?.PURCHASE_FREQUENCY
            : []
        );

        setTransactionsPurchaseFreqCustomer(
          purDistPFFilter?.cust_freq && purDistPFFilter?.cust_freq.length > 0
            ? purDistPFFilter?.cust_freq[0]
            : ""
        );
        setTransactionPurchaseFreqRevenue(
          purDistPFFilter?.rev_freq && purDistPFFilter?.rev_freq.length > 0
            ? purDistPFFilter?.rev_freq[0]
            : ""
        );
      } else {
        setTransactionsPurchaseFreqChartAccountPercent([]);
        setTransactionPurchaseFreqChartRevenuePercent([]);
        setTransactionsPurchaseFreqChartlabel([]);
      }
    }

    if (branchTransactionsRawData) {
      const branchTransFilter = GetObject(
        GetArray(branchTransactionsRawData).filter(
          (item: any) => item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(branchTransFilter).length > 0) {
        setTransactionPurchaseBranchTranChartCustFreq(
          branchTransFilter?.CUST_FREQ ? branchTransFilter?.CUST_FREQ : []
        );
        setTransactionPurchaseBranchTranChartRevFreq(
          branchTransFilter?.REVENUE_FREQ ? branchTransFilter?.REVENUE_FREQ : []
        );
        setTransactionPurchaseBranchTranChartOrdFreq(
          branchTransFilter?.ORDER_FREQ ? branchTransFilter?.ORDER_FREQ : []
        );
        setTransactionPurchaseBranchTranChartLabel(
          branchTransFilter?.BRANCH ? branchTransFilter?.BRANCH : []
        );
      } else {
        setTransactionPurchaseBranchTranChartCustFreq([]);
        setTransactionPurchaseBranchTranChartRevFreq([]);
        setTransactionPurchaseBranchTranChartOrdFreq([]);
        setTransactionPurchaseBranchTranChartLabel([]);
      }
    }

    if (productDistributionRawData) {
      const prodTypeDistFilter = GetObject(
        GetArray(productDistributionRawData).filter(
          (item: any) => item.BRANCH === selectedBranch.value
        )
      );

      if (Object.keys(prodTypeDistFilter).length > 0) {
        setTransactionPurchaseProductTranChartRevenue(
          prodTypeDistFilter?.REVENUE ? prodTypeDistFilter?.REVENUE : []
        );
        setTransactionPurchaseProductTranChartOrdFreq(
          prodTypeDistFilter?.ORDER_FREQUENCY
            ? prodTypeDistFilter?.ORDER_FREQUENCY
            : []
        );
        setTransactionPurchaseProductTranChartLabel(
          prodTypeDistFilter?.product ? prodTypeDistFilter?.product : []
        );
      } else {
        setTransactionPurchaseProductTranChartRevenue([]);
        setTransactionPurchaseProductTranChartOrdFreq([]);
        setTransactionPurchaseProductTranChartLabel([]);
      }
    }

    if (industryRawData) {
      const indRevFilter = GetObject(
        GetArray(industryRawData).filter(
          (item: any) => item.BRANCH === selectedBranch.value
        )
      );

      if (Object.keys(indRevFilter).length > 0) {
        setTransactionPurchaseIndustryChartRevenue(
          indRevFilter?.REVENUE_12M ? indRevFilter?.REVENUE_12M : []
        );
        setTransactionPurchaseIndustryChartAccount(
          indRevFilter?.ACCOUNT ? indRevFilter?.ACCOUNT : []
        );
        setTransactionPurchaseIndustryChartIndustryCount(
          indRevFilter?.industry_count ? indRevFilter?.industry_count : []
        );
        setTransactionPurchaseIndustryChartLabel(
          indRevFilter?.SIC_Code_Description
            ? indRevFilter?.SIC_Code_Description
            : []
        );

        setTransactionPurchaseIndustryMarketShare(
          indRevFilter?.total_market_share &&
            indRevFilter?.total_market_share.length > 0
            ? indRevFilter?.total_market_share[0]
            : ""
        );
        setTransactionPurchaseIndustryTotPotRev(
          WithCommas(
            indRevFilter.total_potential_revenue &&
              indRevFilter?.total_potential_revenue.length > 0
              ? indRevFilter.total_potential_revenue[0]
              : ""
          )
        );
        setTransactionPurchaseIndustryMarkSharInc(
          WithCommas(
            indRevFilter?.market_share_increase &&
              indRevFilter?.market_share_increase.length > 0
              ? indRevFilter?.market_share_increase[0]
              : ""
          )
        );
      } else {
        setTransactionPurchaseIndustryChartRevenue([]);
        setTransactionPurchaseIndustryChartAccount([]);
        setTransactionPurchaseIndustryChartIndustryCount([]);
        setTransactionPurchaseIndustryChartLabel([]);
      }
    }

    if (balanceRawData) {
      const balanceDataFilter = GetObject(
        GetArray(balanceRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(balanceDataFilter).length > 0) {
        const tempTransactionPurchaseRevMonData: any[] = [];
        const tempTransactionPurchaseGrosMargData: any[] = [];
        const tempTransactionPurchaseRolRevData: any[] = [];
        const tempTransactionPurchaseRolGrosData: any[] = [];

        if (
          balanceDataFilter?.forecast_date &&
          balanceDataFilter?.forecast_date?.length ===
            balanceDataFilter?.actual_revenue?.length &&
          balanceDataFilter?.forecast_date?.length ===
            balanceDataFilter?.actual_margin?.length &&
          balanceDataFilter?.forecast_date?.length ===
            balanceDataFilter?.actual_12_rev?.length &&
          balanceDataFilter?.forecast_date?.length ===
            balanceDataFilter?.forecast_plan_revenue?.length &&
          balanceDataFilter?.forecast_date?.length ===
            balanceDataFilter?.forecast_revenue?.length &&
          balanceDataFilter?.forecast_date?.length ===
            balanceDataFilter?.forecast_plan_margin?.length &&
          balanceDataFilter?.forecast_date?.length ===
            balanceDataFilter?.forcast_margin?.length &&
          balanceDataFilter?.forecast_date?.length ===
            balanceDataFilter?.forecast_plan_12_rev?.length &&
          balanceDataFilter?.forecast_date?.length ===
            balanceDataFilter?.forecast_12_rev?.length
        ) {
          balanceDataFilter.forecast_date.forEach(
            (element: any, index: any) => {
              tempTransactionPurchaseRevMonData.push({
                name: element,
                Actual: balanceDataFilter?.actual_revenue[index],
                Plan: balanceDataFilter?.forecast_plan_revenue[index],
                Forecast: balanceDataFilter?.forecast_revenue[index],
              });
              tempTransactionPurchaseGrosMargData.push({
                name: element,
                Actual: balanceDataFilter?.actual_margin[index],
                Plan: balanceDataFilter?.forecast_plan_margin[index],
                Forecast: balanceDataFilter?.forcast_margin[index],
              });
              tempTransactionPurchaseRolRevData.push({
                name: element,
                Actual: balanceDataFilter?.actual_12_rev[index],
                Plan: balanceDataFilter?.forecast_plan_12_rev[index],
                Forecast: balanceDataFilter?.forecast_12_rev[index],
              });
              tempTransactionPurchaseRolGrosData.push({
                name: element,
                Actual: balanceDataFilter?.actual_12_margin[index],
                Plan: balanceDataFilter?.forecast_plan_12_margin[index],
                Forecast: balanceDataFilter?.forcast_12_margin[index],
              });
            }
          );
        }
        setTransactionPurchaseRevMonData(tempTransactionPurchaseRevMonData);
        setTransactionPurchaseGrosMargData(tempTransactionPurchaseGrosMargData);
        setTransactionPurchaseRolRevData(tempTransactionPurchaseRolRevData);
        setTransactionPurchaseRolGrosData(tempTransactionPurchaseRolGrosData);

        let revenueMonthTableRow: any[] = [];

        revenueMonthTableRow.push({
          label: "Actual",
          current:
            "$" +
            WithCommas(
              balanceDataFilter?.current_rev_month_actual &&
                balanceDataFilter?.current_rev_month_actual?.length > 1
                ? Math.trunc(balanceDataFilter?.current_rev_month_actual[1])
                : ""
            ),
          first: "-",
          second: "-",
        });

        revenueMonthTableRow.push({
          label: "Plan",
          current:
            "$" +
            WithCommas(
              balanceDataFilter?.current_rev_month_plan &&
                balanceDataFilter?.current_rev_month_plan?.length > 1
                ? Math.trunc(balanceDataFilter?.current_rev_month_plan[1])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter?.varp_rev_month_plan &&
                balanceDataFilter?.varp_rev_month_plan?.length > 1
                ? balanceDataFilter?.varp_rev_month_plan[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter?.varm_rev_month_plan &&
                balanceDataFilter?.varm_rev_month_plan?.length > 1
                ? Math.trunc(balanceDataFilter?.varm_rev_month_plan[1])
                : ""
            ),
        });

        revenueMonthTableRow.push({
          label: "Forecast",
          current:
            "$" +
            WithCommas(
              balanceDataFilter?.current_rev_month_forecast &&
                balanceDataFilter?.current_rev_month_forecast?.length > 1
                ? Math.trunc(balanceDataFilter?.current_rev_month_forecast[1])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter?.varp_rev_month_forecast &&
                balanceDataFilter?.varp_rev_month_forecast?.length > 1
                ? balanceDataFilter?.varp_rev_month_forecast[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter?.varm_rev_month_forecast &&
                balanceDataFilter?.varm_rev_month_forecast?.length > 1
                ? Math.trunc(balanceDataFilter?.varm_rev_month_forecast[1])
                : ""
            ),
        });

        revenueMonthTableRow.push({
          label: "Prior Year",
          current:
            "$" +
            WithCommas(
              balanceDataFilter?.previous_rev_month_actual &&
                balanceDataFilter?.previous_rev_month_actual?.length > 1
                ? Math.trunc(balanceDataFilter?.previous_rev_month_actual[1])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter?.varp_rev_month_previous &&
                balanceDataFilter?.varp_rev_month_previous?.length > 1
                ? balanceDataFilter?.varp_rev_month_previous[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter?.varm_rev_month_previous &&
                balanceDataFilter?.varm_rev_month_previous?.length > 1
                ? Math.trunc(balanceDataFilter?.varm_rev_month_previous[1])
                : ""
            ),
        });

        setTransactionPurchaseRevMonTableRow(revenueMonthTableRow);

        let grossMarginTableRow: any[] = [];

        grossMarginTableRow.push({
          label: "Actual",
          current:
            "$" +
            WithCommas(
              balanceDataFilter?.current_margin_month_actual &&
                balanceDataFilter?.current_margin_month_actual?.length > 1
                ? Math.trunc(balanceDataFilter.current_margin_month_actual[1])
                : ""
            ),
          first: "-",
          second: "-",
        });

        grossMarginTableRow.push({
          label: "Pan",
          current:
            "$" +
            WithCommas(
              balanceDataFilter?.current_margin_month_plan &&
                balanceDataFilter?.current_margin_month_plan?.length > 1
                ? Math.trunc(balanceDataFilter.current_margin_month_plan[1])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter?.varp_margin_month_plan &&
                balanceDataFilter?.varp_margin_month_plan?.length > 1
                ? balanceDataFilter.varp_margin_month_plan[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter.varm_margin_month_plan &&
                balanceDataFilter?.varm_margin_month_plan?.length > 1
                ? Math.trunc(balanceDataFilter.varm_margin_month_plan[1])
                : ""
            ),
        });

        grossMarginTableRow.push({
          label: "Forecast",
          current:
            "$" +
            WithCommas(
              balanceDataFilter?.current_margin_month_forecast &&
                balanceDataFilter?.current_margin_month_forecast?.length > 1
                ? Math.trunc(balanceDataFilter.current_margin_month_forecast[1])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter?.varp_margin_month_forecast &&
                balanceDataFilter?.varp_margin_month_forecast?.length > 1
                ? balanceDataFilter.varp_margin_month_forecast[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter?.varm_margin_month_forecast &&
                balanceDataFilter?.varm_margin_month_forecast?.length > 1
                ? Math.trunc(balanceDataFilter.varm_margin_month_forecast[1])
                : ""
            ),
        });

        grossMarginTableRow.push({
          label: "Prior Year",
          current:
            "$" +
            WithCommas(
              balanceDataFilter?.previous_margin_month_actual &&
                balanceDataFilter?.previous_margin_month_actual?.length > 1
                ? Math.trunc(balanceDataFilter.previous_margin_month_actual[1])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter?.varp_margin_month_previous &&
                balanceDataFilter?.varp_margin_month_previous?.length > 1
                ? balanceDataFilter.varp_margin_month_previous[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter?.varm_margin_month_previous &&
                balanceDataFilter?.varm_margin_month_previous?.length > 1
                ? Math.trunc(balanceDataFilter.varm_margin_month_previous[1])
                : ""
            ),
        });

        setTransactionPurchaseGrosMargTableRow(grossMarginTableRow);

        let rollingRevenueTableRow: any[] = [];

        rollingRevenueTableRow.push({
          label: "Actual",
          current:
            "$" +
            WithCommas(
              balanceDataFilter.current_rev_ytd_actual &&
                balanceDataFilter?.current_rev_ytd_actual?.length > 0
                ? Math.trunc(balanceDataFilter.current_rev_ytd_actual[0])
                : ""
            ),
          first: "-",
          second: "-",
          third: "-",
          fourth: "-",
          fifth: "-",
        });

        rollingRevenueTableRow.push({
          label: "Plan",
          current:
            "$" +
            WithCommas(
              balanceDataFilter.current_rev_ytd_plan &&
                balanceDataFilter?.current_rev_ytd_plan?.length > 0
                ? Math.trunc(balanceDataFilter.current_rev_ytd_plan[0])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter.varp_rev_ytd_plan &&
                balanceDataFilter?.varp_rev_ytd_plan?.length > 1
                ? balanceDataFilter.varp_rev_ytd_plan[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter.varm_rev_ytd_plan &&
                balanceDataFilter?.varm_rev_ytd_plan?.length > 0
                ? Math.trunc(balanceDataFilter.varm_rev_ytd_plan[0])
                : ""
            ),
          third:
            "$" +
            WithCommas(
              balanceDataFilter.rev_full_plan &&
                balanceDataFilter?.rev_full_plan?.length > 0
                ? Math.trunc(balanceDataFilter.rev_full_plan[0])
                : ""
            ),
          fourth: "-",
          fifth: "-",
        });

        rollingRevenueTableRow.push({
          label: "Forecast",
          current:
            "$" +
            WithCommas(
              balanceDataFilter.rev_ytd_forecast &&
                balanceDataFilter?.rev_ytd_forecast?.length > 0
                ? Math.trunc(balanceDataFilter.rev_ytd_forecast[0])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter.varp_rev_ytd_forecast &&
                balanceDataFilter?.varp_rev_ytd_forecast?.length > 0
                ? balanceDataFilter.varp_rev_ytd_forecast[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter.varm_rev_ytd_forecast &&
                balanceDataFilter?.varm_rev_ytd_forecast?.length > 0
                ? Math.trunc(balanceDataFilter.varm_rev_ytd_forecast[0])
                : ""
            ),
          third:
            "$" +
            WithCommas(
              balanceDataFilter.rev_full_forecast &&
                balanceDataFilter?.rev_full_forecast?.length > 0
                ? Math.trunc(balanceDataFilter.rev_full_forecast[0])
                : ""
            ),
          fourth:
            WithCommas(
              balanceDataFilter.varp_rev_full_forecast &&
                balanceDataFilter?.varp_rev_full_forecast?.length > 1
                ? balanceDataFilter.varp_rev_full_forecast[1]
                : ""
            ) + "%",
          fifth:
            "$" +
            WithCommas(
              balanceDataFilter.varm_rev_full_forecast &&
                balanceDataFilter?.varm_rev_full_forecast?.length > 0
                ? Math.trunc(balanceDataFilter.varm_rev_full_forecast[0])
                : ""
            ),
        });

        rollingRevenueTableRow.push({
          label: "Prior Year",
          current:
            "$" +
            WithCommas(
              balanceDataFilter.previous_rev_ytd_actual &&
                balanceDataFilter?.previous_rev_ytd_actual?.length > 0
                ? Math.trunc(balanceDataFilter.previous_rev_ytd_actual[0])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter.varp_rev_ytd_previous &&
                balanceDataFilter?.varp_rev_ytd_previous?.length > 1
                ? balanceDataFilter.varp_rev_ytd_previous[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter?.varm_rev_ytd_previous &&
                balanceDataFilter?.varm_rev_ytd_previous?.length > 0
                ? Math.trunc(balanceDataFilter?.varm_rev_ytd_previous[0])
                : ""
            ),
          third:
            "$" +
            WithCommas(
              balanceDataFilter?.previous_rev_full_actual &&
                balanceDataFilter?.previous_rev_full_actual?.length > 0
                ? Math.trunc(balanceDataFilter?.previous_rev_full_actual[0])
                : ""
            ),
          fourth:
            WithCommas(
              balanceDataFilter?.varp_rev_full_previous &&
                balanceDataFilter?.varp_rev_full_previous?.length > 1
                ? balanceDataFilter?.varp_rev_full_previous[1]
                : ""
            ) + "%",
          fifth:
            "$" +
            WithCommas(
              balanceDataFilter?.varm_rev_full_previous &&
                balanceDataFilter?.varm_rev_full_previous?.length > 0
                ? Math.trunc(balanceDataFilter?.varm_rev_full_previous[0])
                : ""
            ),
        });

        rollingRevenueTableRow.push({
          GrosMargafrev: "-",
          GrosMargafvar_p: "-",
          GrosMargafvar_m: "-",
        });

        rollingRevenueTableRow.push({});

        rollingRevenueTableRow.push({});

        rollingRevenueTableRow.push({});

        setTransactionPurchaseRolRevTableRow(rollingRevenueTableRow);

        let rollingGrossMarginTableRow: any[] = [];

        rollingGrossMarginTableRow.push({
          label: "Actual",
          current:
            "$" +
            WithCommas(
              balanceDataFilter.current_margin_ytd_actual &&
                balanceDataFilter?.current_margin_ytd_actual?.length > 0
                ? Math.trunc(balanceDataFilter.current_margin_ytd_actual[0])
                : ""
            ),
          first: "-",
          second: "-",
          third: "-",
          fourth: "-",
          fifth: "-",
        });

        rollingGrossMarginTableRow.push({
          label: "Plan",
          current:
            "$" +
            WithCommas(
              balanceDataFilter.current_margin_ytd_plan &&
                balanceDataFilter?.current_margin_ytd_plan?.length > 0
                ? Math.trunc(balanceDataFilter.current_margin_ytd_plan[0])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter.varp_margin_ytd_plan &&
                balanceDataFilter?.varp_margin_ytd_plan?.length > 1
                ? balanceDataFilter.varp_margin_ytd_plan[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter.varm_margin_ytd_plan &&
                balanceDataFilter?.varm_margin_ytd_plan?.length > 0
                ? Math.trunc(balanceDataFilter.varm_margin_ytd_plan[0])
                : ""
            ),
          third:
            "$" +
            WithCommas(
              balanceDataFilter.margin_full_plan &&
                balanceDataFilter?.margin_full_plan?.length > 0
                ? Math.trunc(balanceDataFilter.margin_full_plan[0])
                : ""
            ),
          fourth: "-",
          fifth: "-",
        });

        rollingGrossMarginTableRow.push({
          label: "Forecast",
          current:
            "$" +
            WithCommas(
              balanceDataFilter.margin_ytd_forecast &&
                balanceDataFilter?.margin_ytd_forecast?.length > 0
                ? Math.trunc(balanceDataFilter.margin_ytd_forecast[0])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter.varp_margin_ytd_forecast &&
                balanceDataFilter?.varp_margin_ytd_forecast?.length > 0
                ? balanceDataFilter.varp_margin_ytd_forecast[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter.varm_margin_ytd_forecast &&
                balanceDataFilter?.varm_margin_ytd_forecast?.length > 0
                ? Math.trunc(balanceDataFilter.varm_margin_ytd_forecast[0])
                : ""
            ),
          third:
            "$" +
            WithCommas(
              balanceDataFilter.margin_full_forecast &&
                balanceDataFilter?.margin_full_forecast?.length > 0
                ? Math.trunc(balanceDataFilter.margin_full_forecast[0])
                : ""
            ),
          fourth:
            WithCommas(
              balanceDataFilter.varp_margin_full_forecast &&
                balanceDataFilter?.varp_margin_full_forecast?.length > 1
                ? balanceDataFilter.varp_margin_full_forecast[1]
                : ""
            ) + "%",
          fifth:
            "$" +
            WithCommas(
              balanceDataFilter.varm_margin_full_forecast &&
                balanceDataFilter?.varm_margin_full_forecast?.length > 0
                ? Math.trunc(balanceDataFilter.varm_margin_full_forecast[0])
                : ""
            ),
        });

        rollingGrossMarginTableRow.push({
          label: "Prior Year",
          current:
            "$" +
            WithCommas(
              balanceDataFilter.previous_margin_ytd_actual &&
                balanceDataFilter?.previous_margin_ytd_actual?.length > 0
                ? Math.trunc(balanceDataFilter.previous_margin_ytd_actual[0])
                : ""
            ),
          first:
            WithCommas(
              balanceDataFilter.varp_margin_ytd_previous &&
                balanceDataFilter?.varp_margin_ytd_previous?.length > 1
                ? balanceDataFilter.varp_margin_ytd_previous[1]
                : ""
            ) + "%",
          second:
            "$" +
            WithCommas(
              balanceDataFilter?.varm_margin_ytd_previous &&
                balanceDataFilter?.varm_margin_ytd_previous?.length > 0
                ? Math.trunc(balanceDataFilter?.varm_margin_ytd_previous[0])
                : ""
            ),
          third:
            "$" +
            WithCommas(
              balanceDataFilter?.previous_margin_full_actual &&
                balanceDataFilter?.previous_margin_full_actual?.length > 0
                ? Math.trunc(balanceDataFilter?.previous_margin_full_actual[0])
                : ""
            ),
          fourth:
            WithCommas(
              balanceDataFilter?.varp_margin_full_previous &&
                balanceDataFilter?.varp_margin_full_previous?.length > 1
                ? balanceDataFilter?.varp_margin_full_previous[1]
                : ""
            ) + "%",
          fifth:
            "$" +
            WithCommas(
              balanceDataFilter?.varm_margin_full_previous &&
                balanceDataFilter?.varm_margin_full_previous?.length > 0
                ? Math.trunc(balanceDataFilter?.varm_margin_full_previous[0])
                : ""
            ),
        });

        rollingGrossMarginTableRow.push({
          GrosMargafrev: "-",
          GrosMargafvar_p: "-",
          GrosMargafvar_m: "-",
        });

        rollingGrossMarginTableRow.push({});

        rollingGrossMarginTableRow.push({});

        rollingGrossMarginTableRow.push({});

        setTransactionPurchaseRolGrosTableRow(rollingGrossMarginTableRow);
      } else {
        setTransactionPurchaseRevMonData([]);
        setTransactionPurchaseGrosMargData([]);
        setTransactionPurchaseRolRevData([]);
      }
    }
  };
  useEffect(() => {
    growthForecastFilterData();
  }, [
    selectedBranch,
    selectedDepartment,
    purchaseTagRawData,
    purchaseFreqRawData,
    branchTransactionsRawData,
    productDistributionRawData,
    industryRawData,
    balanceRawData,
  ]);

  const downloadIndustry = () => {
    let all_industry_download_data: any[] = [];
    Object.keys(industryAllRawData).forEach((key: any) => {
      if (key !== "column_order")
        all_industry_download_data.push(...industryAllRawData[key]);
    });
    exportToExcelOther(
      all_industry_download_data,
      "Market Industry Analysis Summary"
    );
  };

  return (
    <Grid container sx={{}}>
      <Grid
        container
        sx={{
          padding: "0px 20px 10px 20px",
          gap: 3,
        }}
      >
        <Grid container item xs={12} sx={{ padding: "10px 10px 5px 10px" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              // padding: "10px",
              width: "100%",
            }}
          >
            <Grid
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                // justifyContent: "center",
                padding: "5px 0px 5px 20px",
                alignItems: "center",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Purchase Distribution
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                padding: "10px 20px 10px 20px",
                border: "1px solid grey",
                backgroundColor: "#FFFFFF",
                gap: 2,
              }}
            >
              {purchaseDistributionLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid container item xs={12} sx={{}}>
                  <Grid
                    container
                    item
                    xs={4}
                    sx={{ padding: "5px", borderRight: "1px solid grey" }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "bold", color: "rgba(0, 0, 0, 1)" }}
                        >
                          LAST PURCHASE PERIOD
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            color: "rgba(49, 144, 242, 1)",
                            fontWeight: "bold",
                          }}
                        >
                          {Number(transactionsLastPurchaseCustomer).toFixed(1)}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(135, 135, 135, 1)",
                            fontSize: "12px",
                          }}
                        >
                          Consumer purchased during the past 30 days and they
                          generated
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            color: "rgba(56, 181, 74, 1)",
                            fontWeight: "bold",
                          }}
                        >
                          {Number(transactionLastPurchaseRevenue).toFixed(1)}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(135, 135, 135, 1)",
                            fontSize: "12px",
                          }}
                        >
                          of the 12mm revenue
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{ height: "400px", width: "100%" }}
                    >
                      <BarChart
                        series={[
                          {
                            data: transactionsLastPurchaseChartAccountPercent,
                            label: "Account",
                            color: "rgba(49, 144, 242, 1)",
                            valueFormatter: (v) => (v === null ? "" : `${v} %`),
                          },
                          {
                            data: transactionLastPurchaseChartRevenuePercent,
                            label: "Revenue",
                            color: "rgba(0, 189, 170, 1)",
                            valueFormatter: (v) => (v === null ? "" : `${v} %`),
                          },
                        ]}
                        yAxis={[
                          {
                            scaleType: "band",
                            data: transactionsLastPurchaseChartlabel,
                          },
                        ]}
                        xAxis={[{ label: "Percentage %" }]}
                        slotProps={{
                          legend: {
                            direction: "row",
                            position: { vertical: "top", horizontal: "right" },
                            padding: 0,
                            itemMarkWidth: 15,
                            itemMarkHeight: 15,
                          },
                        }}
                        margin={{
                          left: 100,
                          right: 20,
                          top: 40,
                          bottom: 50,
                        }}
                        layout="horizontal"
                        grid={{ vertical: true, horizontal: true }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    sx={{ padding: "5px", borderRight: "1px solid grey" }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "bold", color: "rgba(0, 0, 0, 1)" }}
                        >
                          LAST PURCHASE FREQUECY
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            color: "rgba(49, 144, 242, 1)",
                            fontWeight: "bold",
                          }}
                        >
                          {Number(transactionsPurchaseFreqCustomer).toFixed(1)}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(135, 135, 135, 1)",
                            fontSize: "12px",
                          }}
                        >
                          Consumer purchased &gt; 5X during the past 12 months
                          and they generated
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            color: "rgba(56, 181, 74, 1)",
                            fontWeight: "bold",
                          }}
                        >
                          {Number(transactionPurchaseFreqRevenue).toFixed(1)}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(135, 135, 135, 1)",
                            fontSize: "12px",
                          }}
                        >
                          of the 12mm revenue
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ height: "400px" }}>
                      <BarChart
                        series={[
                          {
                            data: transactionsPurchaseFreqChartAccountPercent,
                            label: "Account",
                            color: "rgba(49, 144, 242, 1)",
                            valueFormatter: (v) => (v === null ? "" : `${v} %`),
                          },
                          {
                            data: transactionPurchaseFreqChartRevenuePercent,
                            label: "Revenue",
                            color: "rgba(0, 189, 170, 1)",
                            valueFormatter: (v) => (v === null ? "" : `${v} %`),
                          },
                        ]}
                        yAxis={[
                          {
                            scaleType: "band",
                            data: transactionsPurchaseFreqChartlabel,
                          },
                        ]}
                        xAxis={[{ label: "Percentage %" }]}
                        slotProps={{
                          legend: {
                            direction: "row",
                            position: { vertical: "top", horizontal: "right" },
                            padding: 0,
                            itemMarkWidth: 15,
                            itemMarkHeight: 15,
                          },
                        }}
                        margin={{
                          left: 50,
                          right: 20,
                          top: 40,
                          bottom: 40,
                        }}
                        layout="horizontal"
                        grid={{ vertical: true, horizontal: true }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={4} sx={{ padding: "5px" }}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Grid sx={{}}>
                        <Grid
                          sx={{
                            backgroundColor: "rgba(231, 231, 231, 1)",
                            display: "flex",
                            borderRadius: "10px",
                            padding: "2px",
                          }}
                        >
                          <Grid
                            sx={{ justifyContent: "center", padding: "5px" }}
                          >
                            <Button
                              onClick={() =>
                                handlePurchaseDistributionNav("BRANCH")
                              }
                              variant="outlined"
                              sx={{
                                color: "black",
                                padding: "0px 20px 0px 20px",
                                backgroundColor:
                                  activePurchaseDistribution === "BRANCH"
                                    ? "#FFFFFF"
                                    : "rgba(231, 231, 231, 1)",
                              }}
                            >
                              Branch
                            </Button>
                          </Grid>
                          <Grid
                            sx={{ justifyContent: "center", padding: "5px" }}
                          >
                            <Button
                              onClick={() =>
                                handlePurchaseDistributionNav("DEPARTMENT")
                              }
                              variant="outlined"
                              sx={{
                                color: "black",
                                padding: "0px 20px 0px 20px",
                                backgroundColor:
                                  activePurchaseDistribution === "DEPARTMENT"
                                    ? "#FFFFFF"
                                    : "rgba(231, 231, 231, 1)",
                              }}
                            >
                              Department
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{
                        height: "500px",
                      }}
                    >
                      {activePurchaseDistribution === "BRANCH" ? (
                        <BarChart
                          series={[
                            {
                              data: transactionPurchaseBranchTranChartCustFreq,
                              label: "Account",
                              color: "rgba(49, 144, 242, 1)",
                              valueFormatter: (v) =>
                                v === null ? "" : `${v} %`,
                            },
                            {
                              data: transactionPurchaseBranchTranChartRevFreq,
                              label: "Revenue",
                              color: "rgba(0, 189, 170, 1)",
                              valueFormatter: (v) =>
                                v === null ? "" : `${v} %`,
                            },
                            {
                              data: transactionPurchaseBranchTranChartOrdFreq,
                              label: "Invoice",
                              color: "rgba(90, 238, 158, 1)",
                              valueFormatter: (v) =>
                                v === null ? "" : `${v} %`,
                            },
                          ]}
                          yAxis={[
                            {
                              scaleType: "band",
                              data: transactionPurchaseBranchTranChartLabel,
                            },
                          ]}
                          xAxis={[{ label: "Percentage %" }]}
                          slotProps={{
                            legend: {
                              direction: "row",
                              position: {
                                vertical: "top",
                                horizontal: "right",
                              },
                              padding: 0,
                              itemMarkWidth: 15,
                              itemMarkHeight: 15,
                            },
                          }}
                          margin={{
                            left: 150,
                            right: 0,
                            top: 40,
                            bottom: 40,
                          }}
                          layout="horizontal"
                          grid={{ vertical: true, horizontal: true }}
                        />
                      ) : (
                        <BarChart
                          series={[
                            {
                              data: transactionPurchaseProductTranChartRevenue,
                              label: "Revenue",
                              color: "rgba(0, 189, 170, 1)",
                              valueFormatter: (v) =>
                                v === null ? "" : `${v} %`,
                            },
                            {
                              data: transactionPurchaseProductTranChartOrdFreq,
                              label: "Invoice",
                              color: "rgba(90, 238, 158, 1)",
                              valueFormatter: (v) =>
                                v === null ? "" : `${v} %`,
                            },
                          ]}
                          yAxis={[
                            {
                              scaleType: "band",
                              data: transactionPurchaseProductTranChartLabel,
                            },
                          ]}
                          slotProps={{
                            legend: {
                              direction: "row",
                              position: {
                                vertical: "top",
                                horizontal: "right",
                              },
                              padding: 0,
                              itemMarkWidth: 15,
                              itemMarkHeight: 15,
                            },
                          }}
                          margin={{
                            left: 130,
                            right: 0,
                            top: 40,
                            bottom: 20,
                          }}
                          layout="horizontal"
                          grid={{ vertical: true, horizontal: true }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ padding: "5px 10px 10px 10px" }}>
          <Grid container item xs={6} sx={{ paddingRight: "10px" }}>
            <Grid container sx={{}}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Grid
                  sx={{
                    backgroundColor: "#38B54A",
                    display: "flex",
                    // justifyContent: "center",
                    padding: "5px 0px 5px 20px",
                    alignItems: "center",
                    borderRadius: "5px 5px 0px 0px",
                    border: "1px solid grey",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                  >
                    Top 12 Industries by Revenue
                  </Typography>
                </Grid>
                {industryByRevenueLoading ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "300px",
                      border: "1px solid grey",
                      width: "100%",
                    }}
                  >
                    <MiniSpinner />
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      padding: "10px 20px 10px 20px",
                      border: "1px solid grey",
                      backgroundColor: "#FFFFFF",
                      gap: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <Grid container item xs={12} sx={{}}>
                        <Grid container item xs={4} sx={{ padding: "5px" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              backgroundColor: "rgba(247, 249, 252, 1)",
                              paddingTop: "10px",
                              borderRadius: "5px",
                              justifyContent: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                MARKET SHARE
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(49, 144, 242, 1)",
                                  fontWeight: "bold",
                                }}
                              >
                                {Number(
                                  transactionPurchaseIndustryMarketShare
                                ).toFixed(1)}
                                %
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container item xs={4} sx={{ padding: "5px" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              backgroundColor: "rgba(247, 249, 252, 1)",
                              borderRadius: "5px",
                              paddingTop: "10px",
                              justifyContent: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                POTENTIAL REVENUE
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(56, 181, 74, 1)",
                                  fontWeight: "bold",
                                }}
                              >
                                ${transactionPurchaseIndustryTotPotRev}M
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container item xs={4} sx={{ padding: "5px" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              backgroundColor: "rgba(247, 249, 252, 1)",
                              borderRadius: "5px",
                              paddingTop: "10px",
                              justifyContent: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                POTENTIAL REVENUE
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "rgba(135, 135, 135, 1)",
                                }}
                              >
                                if share increase 1%
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "rgba(0, 70, 128, 1)",
                                  fontWeight: "bold",
                                }}
                              >
                                ${transactionPurchaseIndustryMarkSharInc}M
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid sx={{}}>
                        <Grid
                          sx={{
                            display: "flex",
                            backgroundColor: "rgba(231, 231, 231, 1)",
                            borderRadius: "10px",
                          }}
                        >
                          <Grid
                            sx={{
                              justifyContent: "center",
                              padding: "5px",
                            }}
                          >
                            <Button
                              onClick={() => downloadIndustry()}
                              variant="outlined"
                              sx={{
                                color: "black",
                                padding: "0px 20px 0px 20px",
                                backgroundColor: "#FFFFFF",
                              }}
                            >
                              Download
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ height: "1100px" }}>
                      <BarChart
                        series={[
                          {
                            data: transactionPurchaseIndustryChartRevenue,
                            label: "Revenue",
                            xAxisKey: "bottom_axis_id",
                            color: "rgba(0, 189, 170, 1)",
                            valueFormatter: (v) =>
                              v === null ? "" : `$ ${WithCommas(v)}`,
                          },
                          {
                            data: transactionPurchaseIndustryChartAccount,
                            label: "Customer Count",
                            xAxisKey: "top_axis_id",
                            color: "rgba(49, 144, 242, 1)",
                          },
                          {
                            data: transactionPurchaseIndustryChartIndustryCount,
                            label: "Prospect Count",
                            xAxisKey: "top_axis_id",
                            color: "rgba(90, 238, 158, 1)",
                          },
                        ]}
                        yAxis={[
                          {
                            data: transactionPurchaseIndustryChartLabel,
                            scaleType: "band",
                          },
                        ]}
                        xAxis={[
                          {
                            id: "bottom_axis_id",
                            scaleType: "linear",
                            valueFormatter: (v) =>
                              v === null ? "" : `$ ${WithCommas(v)}`,
                          },
                          {
                            id: "top_axis_id",
                            scaleType: "linear",
                          },
                        ]}
                        slotProps={{
                          legend: {
                            direction: "row",
                            position: { vertical: "top", horizontal: "right" },
                            padding: 0,
                            itemMarkWidth: 15,
                            itemMarkHeight: 15,
                          },
                        }}
                        margin={{
                          left: 200,
                          right: 0,
                          top: 60,
                          bottom: 20,
                        }}
                        layout="horizontal"
                        grid={{ vertical: true, horizontal: true }}
                        topAxis="top_axis_id"
                        bottomAxis="bottom_axis_id"
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6} sx={{ paddingLeft: "10px", gap: 3 }}>
            <Grid container item xs={12} sx={{}}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Grid
                  sx={{
                    backgroundColor: "#38B54A",
                    display: "flex",
                    // justifyContent: "center",
                    padding: "5px 0px 5px 20px",
                    alignItems: "center",
                    borderRadius: "5px 5px 0px 0px",
                    border: "1px solid grey",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                  >
                    Rolling 12 Month{" "}
                    {activeRevenueMonth === "REVENUE"
                      ? "Revenue Plan"
                      : "Gross Margin Plan"}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                  ></Typography>
                </Grid>
                {forecastRevenuePlanLoading ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "300px",
                      border: "1px solid grey",
                      width: "100%",
                    }}
                  >
                    <MiniSpinner />
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      padding: "10px 20px 10px 20px",
                      border: "1px solid grey",
                      backgroundColor: "#FFFFFF",
                      gap: 2,
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{
                        padding: "5px",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Grid sx={{}}>
                          <Grid
                            sx={{
                              display: "flex",
                              backgroundColor: "rgba(231, 231, 231, 1)",
                              padding: "2px",
                              borderRadius: "10px",
                            }}
                          >
                            <Grid
                              sx={{
                                justifyContent: "center",
                                padding: "5px",
                              }}
                            >
                              <Button
                                onClick={() => handleRevenueMonthNav("REVENUE")}
                                variant="outlined"
                                sx={{
                                  color: "black",
                                  padding: "0px 20px 0px 20px",
                                  backgroundColor:
                                    activeRevenueMonth === "REVENUE"
                                      ? "#FFFFFF"
                                      : "rgba(231, 231, 231, 1)",
                                }}
                              >
                                REVENUE
                              </Button>
                            </Grid>
                            <Grid
                              sx={{
                                justifyContent: "center",
                                padding: "5px",
                              }}
                            >
                              <Button
                                onClick={() => handleRevenueMonthNav("MARGIN")}
                                variant="outlined"
                                sx={{
                                  color: "black",
                                  padding: "0px 20px 0px 20px",
                                  backgroundColor:
                                    activeRevenueMonth === "MARGIN"
                                      ? "#FFFFFF"
                                      : "rgba(231, 231, 231, 1)",
                                }}
                              >
                                MARGIN
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {activeRevenueMonth === "REVENUE" ? (
                        <TableContainer component={Paper}>
                          <Table size="small" aria-label="simple table">
                            <TableHead>
                              <TableRow
                                sx={{
                                  borderBottom: "none",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  YTD
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  VAR %
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  VAR $
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  FULL YEAR
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  VAR $
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  VAR $
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {transactionPurchaseRolRevTableRow.map((row) => (
                                <TableRow
                                  key={row.label}
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                    sx={{
                                      borderBottom: "none",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {row.label}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.current}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.first}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.second}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.third}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.fourth}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.fifth}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <TableContainer component={Paper}>
                          <Table size="small" aria-label="simple table">
                            <TableHead>
                              <TableRow
                                sx={{
                                  borderBottom: "none",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                ></TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  YTD
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  VAR %
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  VAR $
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  FULL YEAR
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  VAR $
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    fontWeight: "bold",
                                  }}
                                  align="center"
                                >
                                  VAR $
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {transactionPurchaseRolGrosTableRow.map((row) => (
                                <TableRow
                                  key={row.label}
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                    sx={{
                                      borderBottom: "none",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {row.label}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.current}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.first}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.second}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.third}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.fourth}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                    align="center"
                                  >
                                    {row.fifth}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                    <Grid container item xs={12} sx={{ height: "350px" }}>
                      {activeRevenueMonth === "REVENUE" ? (
                        <ResponsiveContainer width="100%" height="100%">
                          <ComposedChart
                            data={transactionPurchaseRolRevData}
                            margin={{
                              top: 20,
                              right: 20,
                              bottom: 50,
                              left: 20,
                            }}
                          >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis
                              dataKey="name"
                              scale="auto"
                              angle={-80}
                              tickMargin={30}
                              style={{
                                fontSize: "12px",
                              }}
                              interval={0}
                            />
                            <YAxis
                              tickFormatter={(value) =>
                                `$ ${new Intl.NumberFormat("en-US", {
                                  notation: "compact",
                                  compactDisplay: "short",
                                }).format(value)}`
                              }
                            />
                            <Tooltip content={<CustomTooltipRevenue />} />
                            <Legend
                              verticalAlign="top"
                              // layout="vertical"
                              align="right"
                              wrapperStyle={{
                                paddingBottom: "20px",
                              }}
                            />
                            <Bar
                              dataKey="Actual"
                              barSize={30}
                              fill="rgba(49, 144, 242, 1)"
                              // label={{ position: "top" }}
                            />
                            <Bar
                              dataKey="Plan"
                              barSize={30}
                              fill="rgba(80, 205, 137, 1)"
                              // label={{ position: "top" }}
                            />
                            <Line
                              type="monotone"
                              dataKey="Forecast"
                              stroke="rgba(240, 88, 34, 1)"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer width="100%" height="100%">
                          <ComposedChart
                            data={transactionPurchaseRolGrosData}
                            margin={{
                              top: 20,
                              right: 20,
                              bottom: 50,
                              left: 20,
                            }}
                          >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis
                              dataKey="name"
                              scale="auto"
                              angle={-80}
                              tickMargin={30}
                              style={{
                                fontSize: "12px",
                              }}
                              interval={0}
                            />
                            <YAxis
                              tickFormatter={(value) =>
                                `$ ${new Intl.NumberFormat("en-US", {
                                  notation: "compact",
                                  compactDisplay: "short",
                                }).format(value)}`
                              }
                            />
                            <Tooltip content={<CustomTooltipRevenue />} />
                            <Legend
                              verticalAlign="top"
                              align="right"
                              wrapperStyle={{
                                paddingBottom: "20px",
                              }}
                            />
                            <Bar
                              dataKey="Actual"
                              barSize={30}
                              fill="rgba(49, 144, 242, 1)"
                            />
                            <Bar
                              dataKey="Plan"
                              barSize={30}
                              fill="rgba(80, 205, 137, 1)"
                            />
                            <Line
                              type="monotone"
                              dataKey="Forecast"
                              stroke="rgba(240, 88, 34, 1)"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={{}}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Grid
                  sx={{
                    backgroundColor: "#38B54A",
                    display: "flex",
                    // justifyContent: "center",
                    padding: "5px 0px 5px 20px",
                    alignItems: "center",
                    borderRadius: "5px 5px 0px 0px",
                    border: "1px solid grey",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                  >
                    Monthly
                    {activeRevenueMonth === "REVENUE"
                      ? " Revenue Plan"
                      : " Gross Margin Plan"}
                  </Typography>
                </Grid>
                {forecastRevenuePlanLoading ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "300px",
                      border: "1px solid grey",
                      width: "100%",
                    }}
                  >
                    <MiniSpinner />
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      padding: "10px 20px 10px 20px",
                      border: "1px solid grey",
                      backgroundColor: "#FFFFFF",
                      gap: 2,
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          padding: "5px",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Grid sx={{}}>
                            <Grid
                              sx={{
                                display: "flex",
                                backgroundColor: "rgba(231, 231, 231, 1)",
                                padding: "2px",
                                borderRadius: "10px",
                              }}
                            >
                              <Grid
                                sx={{
                                  justifyContent: "center",
                                  padding: "5px",
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    handleRevenueMonthNav("REVENUE")
                                  }
                                  variant="outlined"
                                  sx={{
                                    color: "black",
                                    padding: "0px 20px 0px 20px",
                                    backgroundColor:
                                      activeRevenueMonth === "REVENUE"
                                        ? "#FFFFFF"
                                        : "rgba(231, 231, 231, 1)",
                                  }}
                                >
                                  REVENUE
                                </Button>
                              </Grid>
                              <Grid
                                sx={{
                                  justifyContent: "center",
                                  padding: "5px",
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    handleRevenueMonthNav("MARGIN")
                                  }
                                  variant="outlined"
                                  sx={{
                                    color: "black",
                                    padding: "0px 20px 0px 20px",
                                    backgroundColor:
                                      activeRevenueMonth === "MARGIN"
                                        ? "#FFFFFF"
                                        : "rgba(231, 231, 231, 1)",
                                  }}
                                >
                                  MARGIN
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          padding: "5px",
                        }}
                      >
                        <TableContainer component={Paper}>
                          {activeRevenueMonth === "REVENUE" ? (
                            <Table size="small" aria-label="simple table">
                              <TableHead>
                                <TableRow
                                  key={"head"}
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                  ></TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      fontWeight: "bold",
                                    }}
                                    align="center"
                                  >
                                    CURRENT MONTH
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      fontWeight: "bold",
                                    }}
                                    align="center"
                                  >
                                    VAR %
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      fontWeight: "bold",
                                    }}
                                    align="center"
                                  >
                                    VAR $
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {transactionPurchaseRevMonTableRow.map(
                                  (row) => (
                                    <TableRow
                                      key={row.label}
                                      sx={{
                                        borderBottom: "none",
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="right"
                                        sx={{
                                          borderBottom: "none",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {row.label}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                        }}
                                        align="center"
                                      >
                                        {row.current}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                        }}
                                        align="center"
                                      >
                                        {row.first}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                        }}
                                        align="center"
                                      >
                                        {row.second}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          ) : (
                            <Table size="small" aria-label="simple table">
                              <TableHead>
                                <TableRow
                                  sx={{
                                    borderBottom: "none",
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                    }}
                                  ></TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      fontWeight: "bold",
                                    }}
                                    align="center"
                                  >
                                    CURRENT MONTH
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      fontWeight: "bold",
                                    }}
                                    align="center"
                                  >
                                    VAR %
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: "none",
                                      fontWeight: "bold",
                                    }}
                                    align="center"
                                  >
                                    VAR $
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {transactionPurchaseGrosMargTableRow.map(
                                  (row) => (
                                    <TableRow
                                      key={row.label}
                                      sx={{
                                        borderBottom: "none",
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="right"
                                        sx={{
                                          borderBottom: "none",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {row.label}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                        }}
                                        align="center"
                                      >
                                        {row.current}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                        }}
                                        align="center"
                                      >
                                        {row.first}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                        }}
                                        align="center"
                                      >
                                        {row.second}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ height: "350px" }}>
                      {activeRevenueMonth === "REVENUE" ? (
                        <ResponsiveContainer width="100%" height="100%">
                          <ComposedChart
                            data={transactionPurchaseRevMonData}
                            margin={{
                              top: 20,
                              right: 20,
                              bottom: 50,
                              left: 0,
                            }}
                          >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis
                              dataKey="name"
                              scale="auto"
                              angle={-80}
                              tickMargin={30}
                              style={{
                                fontSize: "12px",
                              }}
                              interval={0}
                            />
                            <YAxis
                              tickFormatter={(value) =>
                                `$ ${new Intl.NumberFormat("en-US", {
                                  notation: "compact",
                                  compactDisplay: "short",
                                }).format(value)}`
                              }
                            />
                            <Tooltip content={<CustomTooltipRevenue />} />
                            <Legend
                              verticalAlign="top"
                              // layout="vertical"
                              align="right"
                              wrapperStyle={{
                                paddingBottom: "20px",
                              }}
                            />
                            <Bar
                              dataKey="Actual"
                              barSize={30}
                              fill="rgba(49, 144, 242, 1)"
                              // label={{ position: "top" }}
                            />
                            <Bar
                              dataKey="Plan"
                              barSize={30}
                              fill="rgba(80, 205, 137, 1)"
                              // label={{ position: "top" }}
                            />
                            <Line
                              type="monotone"
                              dataKey="Forecast"
                              stroke="rgba(240, 88, 34, 1)"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer width="100%" height="100%">
                          <ComposedChart
                            data={transactionPurchaseGrosMargData}
                            margin={{
                              top: 20,
                              right: 20,
                              bottom: 50,
                              left: 0,
                            }}
                          >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis
                              dataKey="name"
                              scale="auto"
                              angle={-80}
                              tickMargin={30}
                              style={{
                                fontSize: "12px",
                              }}
                              interval={0}
                            />
                            <YAxis
                              tickFormatter={(value) =>
                                `$ ${new Intl.NumberFormat("en-US", {
                                  notation: "compact",
                                  compactDisplay: "short",
                                }).format(value)}`
                              }
                            />
                            <Tooltip content={<CustomTooltipRevenue />} />
                            <Legend
                              verticalAlign="top"
                              // layout="vertical"
                              align="right"
                              wrapperStyle={{
                                paddingBottom: "20px",
                              }}
                            />
                            <Bar
                              dataKey="Actual"
                              barSize={30}
                              fill="rgba(49, 144, 242, 1)"
                              // label={{ position: "top" }}
                            />
                            <Bar
                              dataKey="Plan"
                              barSize={30}
                              fill="rgba(80, 205, 137, 1)"
                              // label={{ position: "top" }}
                            />
                            <Line
                              type="monotone"
                              dataKey="Forecast"
                              stroke="rgba(240, 88, 34, 1)"
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Transactions;
