import {
  Button,
  Divider,
  Grid,
  Typography,
  Snackbar,
  Box,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import TableTemplate from "../../../components/reusableComponents/tableTemplate/tableTemplate";
import CreateUserDialog from "./createUserDialog/createUserDialog";
import EditUserDialog from "./editUserDialog/editUserDialog";
import DisableUserDialog from "./disableUserDialog/disableUserDialog";
import CancelSubscriptionDialog from "./cancelSubscriptionDialog/cancelSubscriptionDialog";
import { getUsers } from "../../../store/thunks/systemManagementThunk";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import LockResetIcon from "@mui/icons-material/LockReset";
import EnableUserDialog from "./enableUserDialog/enableUserDialog";
import {
  logoutUser,
  resetPasswordLink,
} from "../../../store/thunks/authenticationThunk";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { logout } from "../../../store/features/authentication/autehnticationSlice";
import { useNavigate } from "react-router-dom";
import { portalManagementReset } from "../../../store/features/portalManagement/portalManagementSlice";
import { systemManagementReset } from "../../../store/features/accountManagement/systemManagementSlice";

const UserManagement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { allUsers, getUserLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);

  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [editUserRowData, setEditUserRowData] = useState<any>();

  const [openDisableUserDialog, setOpenDisableUserDialog] = useState(false);
  const [openEnableUserDialog, setOpenEnableUserDialog] = useState(false);
  const [disableUserRowData, setDisableUserRowData] = useState<any>();

  const [openCancelSubscriptionDialog, setOpenCancelSubscriptionDialog] =
    useState(false);
  const [CancelSubscriptionRowData, setCancelSubscriptionRowData] =
    useState<any>();

  const [selectedUserNavigation, setSelectedUserNavigation] =
    useState("active_user");

  const [userList, setUserList] = useState<any>([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const {
    data: { data = [] } = {}, //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = await dispatch(getUsers({}))
        .then((getUsersResult: any) => {
          if (getUsersResult?.error) {
            if (getUsersResult?.error?.message === "Rejected") {
              dispatch(logoutUser({ user_index: userInfo?.client_user_index }));
              dispatch(portalManagementReset());
              dispatch(systemManagementReset());
              dispatch(logout());
              navigate("/login");
            }
          }
          if (getUsersResult.payload.all_users) {
            setUserList(getUsersResult.payload.all_users);
            handleUserNavigation(
              selectedUserNavigation,
              getUsersResult.payload.all_users
            );
          }
        })

        .catch((error) => {});
      return response ? response : {};
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const userColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "Table",
        header: "",
        columns: [
          {
            accessorFn: (row: any) => `${row.first_name} ${row.last_name}`, //accessorFn used to join multiple data into a single cell
            id: "name", //id is still required when using accessorFn instead of accessorKey
            header: "Full Name",
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <span>{renderedCellValue}</span>
              </Box>
            ),
          },
          {
            accessorKey: "user_name", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Email",
          },
          {
            accessorKey: "role", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Role",
          },
          {
            accessorKey: "customer_name", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Clients",
          },
          {
            accessorKey: "client_type_name", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Client Type",
          },
          {
            accessorKey: "salemen_name", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Sale Rep",
          },
          {
            accessorKey: "login_status", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Logged In",
          },
          {
            accessorKey: "last_login", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Last Login",
          },
          {
            accessorKey: "login_count",
            header: "Login Count",
          },
        ],
      },
      // {
      //   id: "Full Name", //id used to define `group` column
      //   header: "",
      //   columns: [
      //     {
      //       accessorFn: (row) => `${row.firstName} ${row.lastName}`, //accessorFn used to join multiple data into a single cell
      //       id: "name", //id is still required when using accessorFn instead of accessorKey
      //       header: "Name",
      //       size: 250,
      //       Cell: ({ renderedCellValue, row }) => (
      //         <Box
      //           sx={{
      //             display: "flex",
      //             alignItems: "center",
      //             gap: "1rem",
      //           }}
      //         >
      //           <span>{renderedCellValue}</span>
      //         </Box>
      //       ),
      //     },
      //     {
      //       accessorKey: "email", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      //       enableClickToCopy: true,
      //       filterVariant: "autocomplete",
      //       header: "Email",
      //       size: 300,
      //     },
      //   ],
      // },
      // {
      //   id: "id",
      //   header: "",
      //   columns: [
      //     {
      //       accessorKey: "salary",
      //       // filterVariant: 'range', //if not using filter modes feature, use this instead of filterFn
      //       filterFn: "between",
      //       header: "Salary",
      //       size: 200,
      //       //custom conditional format and styling
      //       Cell: ({ cell }) => (
      //         <Box
      //           component="span"
      //           sx={(theme) => ({
      //             backgroundColor:
      //               cell.getValue<number>() < 50_000
      //                 ? theme.palette.error.dark
      //                 : cell.getValue<number>() >= 50_000 &&
      //                   cell.getValue<number>() < 75_000
      //                 ? theme.palette.warning.dark
      //                 : theme.palette.success.dark,
      //             borderRadius: "0.25rem",
      //             color: "#fff",
      //             maxWidth: "9ch",
      //             p: "0.25rem",
      //           })}
      //         >
      //           {cell.getValue<number>()?.toLocaleString?.("en-US", {
      //             style: "currency",
      //             currency: "USD",
      //             minimumFractionDigits: 0,
      //             maximumFractionDigits: 0,
      //           })}
      //         </Box>
      //       ),
      //     },
      //     {
      //       accessorKey: "jobTitle", //hey a simple column for once
      //       header: "Job Title",
      //       size: 350,
      //     },
      //     {
      //       accessorFn: (row) => new Date(row.startDate), //convert to Date for sorting and filtering
      //       id: "startDate",
      //       header: "Start Date",
      //       filterVariant: "date",
      //       filterFn: "lessThan",
      //       sortingFn: "datetime",
      //       Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
      //       Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      //       muiFilterTextFieldProps: {
      //         sx: {
      //           minWidth: "250px",
      //         },
      //       },
      //     },
      //   ],
      // },
    ],
    []
  );

  const userTable = useMaterialReactTable({
    columns: userColumns,
    data: allUsers ? allUsers : [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      isLoading: getUserLoading, //cell skeletons and loading overlay
      // showProgressBars: getUserLoading, //progress bars while refetching
      // isSaving: getUserLoading, //progress bars and save button spinners
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       alignItems: "center",
    //       display: "flex",
    //       justifyContent: "space-around",
    //       left: "30px",
    //       maxWidth: "1000px",
    //       position: "sticky",
    //       width: "100%",
    //     }}
    //   >
    //     <Box sx={{ textAlign: "center" }}>
    //       <Typography variant="h4">Signature Catch Phrase:</Typography>
    //       <Typography variant="h1">
    //         &quot;{row.original.signatureCatchPhrase}&quot;
    //       </Typography>
    //     </Box>
    //   </Box>
    // ),
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      selectedUserNavigation === "active_user" && (
        <MenuItem
          key={0}
          onClick={() => {
            handleOpenEditUser(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit User
        </MenuItem>
      ),
      selectedUserNavigation === "active_user" && (
        <MenuItem
          key={1}
          onClick={() => {
            handleDisableUser(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Disable User
        </MenuItem>
      ),
      selectedUserNavigation === "active_user" && (
        <MenuItem
          key={2}
          onClick={() => {
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <UnsubscribeIcon />
          </ListItemIcon>
          Attach Subscription
        </MenuItem>
      ),
      selectedUserNavigation === "active_user" && (
        <MenuItem
          key={2}
          onClick={() => {
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <UnsubscribeIcon />
          </ListItemIcon>
          Edit Subscription
        </MenuItem>
      ),
      // selectedUserNavigation === "active_user" && (
      //   <MenuItem
      //     key={2}
      //     onClick={() => {
      //       closeMenu();
      //     }}
      //     sx={{ m: 0 }}
      //   >
      //     <ListItemIcon>
      //       <UnsubscribeIcon />
      //     </ListItemIcon>
      //     Edit Email Report
      //   </MenuItem>
      // ),
      selectedUserNavigation === "active_user" && (
        <MenuItem
          key={3}
          onClick={() => {
            handleResetPassword(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <LockResetIcon />
          </ListItemIcon>
          Reset Passowrd
        </MenuItem>
      ),
      selectedUserNavigation !== "active_user" && (
        <MenuItem
          key={4}
          onClick={() => {
            handleEnableUser(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Enable User
        </MenuItem>
      ),
    ],
    // renderTopToolbar: ({ table }) => {
    //   const handleDeactivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert("deactivating " + row.getValue("name"));
    //     });
    //   };

    //   const handleActivate = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert("activating " + row.getValue("name"));
    //     });
    //   };

    //   const handleContact = () => {
    //     table.getSelectedRowModel().flatRows.map((row) => {
    //       alert("contact " + row.getValue("name"));
    //     });
    //   };

    //   return (
    //     <Box
    //       sx={(theme) => ({
    //         backgroundColor: lighten(theme.palette.background.default, 0.05),
    //         display: "flex",
    //         gap: "0.5rem",
    //         p: "8px",
    //         justifyContent: "space-between",
    //       })}
    //     >
    //       <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
    //         {/* import MRT sub-components */}
    //         <MRT_GlobalFilterTextField table={table} />
    //         <MRT_ToggleFiltersButton table={table} />
    //       </Box>
    //       <Box>
    //         <Box sx={{ display: "flex", gap: "0.5rem" }}>
    //           <Button
    //             color="error"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleDeactivate}
    //             variant="contained"
    //           >
    //             Deactivate
    //           </Button>
    //           <Button
    //             color="success"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleActivate}
    //             variant="contained"
    //           >
    //             Activate
    //           </Button>
    //           <Button
    //             color="info"
    //             disabled={!table.getIsSomeRowsSelected()}
    //             onClick={handleContact}
    //             variant="contained"
    //           >
    //             Contact
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //   );
    // },
  });

  const handleUserNavigation = (active_user: any, user_list?: any) => {
    let table_user_list = [];

    if (user_list) {
      if (active_user === "active_user") {
        table_user_list = user_list.filter((user: any) => {
          return user.status === "Active";
        });
      } else {
        table_user_list = user_list.filter((user: any) => {
          return user.status !== "Active";
        });
      }
    } else {
      if (active_user === "active_user") {
        table_user_list = allUsers
          ? allUsers.filter((user: any) => {
              return user.status === "Active";
            })
          : [];
      } else {
        table_user_list = allUsers
          ? allUsers.filter((user: any) => {
              return user.status !== "Active";
            })
          : [];
      }
    }

    setSelectedUserNavigation(active_user);
    setUserList(table_user_list);
  };

  useEffect(() => {
    handleUserNavigation(selectedUserNavigation);
  }, [allUsers]);

  const handleOpenCreateUser = () => {
    setOpenCreateUserDialog(true);
  };
  const handleCloseCreateUser = (message: string) => {
    if (message !== "") {
      setUserList([]);
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setOpenCreateUserDialog(false);
  };

  const handleOpenEditUser = (rowData: any) => {
    setEditUserRowData(rowData);
    setOpenEditUserDialog(true);
  };
  const handleCloseEditUser = (message: string) => {
    if (message !== "") {
      setUserList([]);
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setEditUserRowData(null);
    setOpenEditUserDialog(false);
  };

  const handleDisableUser = (rowData: any) => {
    setDisableUserRowData(rowData);
    setOpenDisableUserDialog(true);
  };
  const handleCloseDisableUser = (message: string) => {
    if (message !== "") {
      setUserList([]);
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setDisableUserRowData(null);
    setOpenDisableUserDialog(false);
  };

  const handleEnableUser = (rowData: any) => {
    setDisableUserRowData(rowData);
    setOpenEnableUserDialog(true);
  };
  const handleCloseEnableUser = (message: string) => {
    if (message !== "") {
      setUserList([]);
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      dispatch(getUsers({}));
    }
    setDisableUserRowData(null);
    setOpenEnableUserDialog(false);
  };

  const handleOpenCancelSubscription = (rowData: any) => {
    setCancelSubscriptionRowData(rowData);
    setOpenCancelSubscriptionDialog(true);
  };
  const handleCloseCancelSubscription = () => {
    setCancelSubscriptionRowData(null);
    setOpenCancelSubscriptionDialog(false);
  };

  const handleResetPassword = (rowData: any) => {
    dispatch(resetPasswordLink({ username: rowData.user_name })).then(
      (result: any) => {
        setSnackbarMessage(result.payload.message);
        setOpenSnackbar(true);
      }
    );
  };

  return (
    <Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px 10px 20px",
        }}
      >
        <Typography variant="h4" sx={{ color: "#004680", fontWeight: "bold" }}>
          {"User Management"}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 20px 10px 20px !important",
        }}
      >
        <Grid sx={{ justifyContent: "center", padding: "5px" }}>
          <Button
            onClick={() => handleOpenCreateUser()}
            variant="outlined"
            sx={{
              color: "white !important",
              fontWeight: "bold !important",
              padding: "0px 20px 0px 20px !important",
              backgroundColor: "#38B54A !important",
            }}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          padding: "0px 20px 20px 20px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#4C4C4C !important",
            borderTop: "5px solid white !important",
            height: "40px",
            paddingLeft: "10px",
          }}
        >
          <Button
            onClick={() => {
              handleUserNavigation("active_user");
            }}
            sx={{
              color:
                selectedUserNavigation === "active_user"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            Active Users
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: "white", backgroundColor: "white" }}
          />
          <Button
            onClick={() => {
              handleUserNavigation("disabled_user");
            }}
            sx={{
              color:
                selectedUserNavigation === "disabled_user"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            Disabled Users
          </Button>
        </Grid>
        <Grid container item xs={12} sx={{ padding: "20px 0px 20px 0px" }}>
          <TableTemplate tableData={userTable} />
        </Grid>
      </Grid>
      <CreateUserDialog
        open={openCreateUserDialog}
        handleCreateClose={(meassge: string) => handleCloseCreateUser(meassge)}
      />
      <EditUserDialog
        open={openEditUserDialog}
        handleEditClose={(meassge: string) => handleCloseEditUser(meassge)}
        editUserData={editUserRowData}
      />
      <DisableUserDialog
        open={openDisableUserDialog}
        handleDisableClose={handleCloseDisableUser}
        disableUserData={disableUserRowData}
      />
      <EnableUserDialog
        open={openEnableUserDialog}
        handleEnableClose={handleCloseEnableUser}
        enableUserData={disableUserRowData}
      />
      <CancelSubscriptionDialog
        open={openCancelSubscriptionDialog}
        handleClose={handleCloseCancelSubscription}
        CancelSubscriptionData={CancelSubscriptionRowData}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={10000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
    </Grid>
  );
};
export default UserManagement;
