import {
  Box,
  Button,
  Divider,
  Grid,
  ListItemIcon,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import TableTemplate from "../../../components/reusableComponents/tableTemplate/tableTemplate";
import { getClients } from "../../../store/thunks/systemManagementThunk";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateClientDialog from "./createClientDialog/createClientDialog";
import DisableClientDialog from "./disableClientDialog/disableClientDialog";
import EnableClientDialog from "./enableClientDialog/enableClientDialog";
import EditClientDialog from "./editClientDialog/editClientDialog";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

const ClientManagement = () => {
  const dispatch = useAppDispatch();
  const { allClients, getClientsLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );
  const [openCreateClientDialog, setOpenCreateClientDialog] = useState(false);

  const [openEditClientDialog, setOpenEditClientDialog] = useState(false);

  const [editClientRowData, setEditClientRowData] = useState<any>();
  const [disableClientRowData, setDisableClientRowData] = useState<any>();
  const [enableClientRowData, setEnableClientRowData] = useState<any>();

  const [openDisableClientDialog, setOpenDisableClientDialog] = useState(false);
  const [openEnableClientDialog, setOpenEnableClientDialog] = useState(false);

  const [selectedClientNavigation, setSelectedClientNavigation] =
    useState("active_client");

  const [clientList, setClientList] = useState<any>([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleClientNavigation = (active_client: any, client_list?: any) => {
    let table_client_list: any = [];

    if (client_list) {
      if (active_client === "active_client") {
        table_client_list = client_list?.filter((client: any) => {
          return client.client_status === "Active";
        });
      } else {
        table_client_list = client_list?.filter((client: any) => {
          return client.client_status !== "Active";
        });
      }
    } else {
      if (active_client === "active_client") {
        table_client_list = allClients?.filter((client: any) => {
          return client.client_status === "Active";
        });
      } else {
        table_client_list = allClients?.filter((client: any) => {
          return client.client_status !== "Active";
        });
      }
      setSelectedClientNavigation(active_client);
    }

    setClientList(table_client_list);
  };

  const {
    data: { data = [] } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = await dispatch(getClients({}))
        .then((result: any) => {
          if (result.payload.clients) {
            handleClientNavigation(
              selectedClientNavigation,
              result.payload.clients
            );
          }
        })
        .catch((error) => {});
      return response ? response : {};
    },
    placeholderData: keepPreviousData,
  });

  const clientColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "Table",
        header: "",
        columns: [
          {
            accessorKey: "customer_name",
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Client Name",
          },
          {
            accessorKey: "customer_id",
            header: "Client ID",
          },
          {
            accessorFn: (row: any) =>
              `${row?.programs
                ?.map((program: any) => program?.program_name)
                .join(", ")}`,
            id: "name",
            filterVariant: "autocomplete",
            header: "Programs",
          },
          {
            accessorKey: "create_date",
            accessorFn: (row: any) =>
              `${dayjs(row?.create_date).format("MMM DD, YYYY")}`,
            header: "Start Date",
          },
          {
            accessorKey: "client_status",
            header: "Status",
          },
          {
            accessorKey: "client_number",
            header: "Number of User",
          },
          {
            accessorKey: "google_rating",
            header: "Google Rating",
          },
          {
            accessorKey: "glassdoor_rating",
            header: "Glassdoor Rating",
          },
        ],
      },
    ],
    []
  );

  const clientTable = useMaterialReactTable({
    columns: clientColumns,
    data: clientList ? clientList : [],
    state: {
      isLoading: getClientsLoading,
      // showProgressBars: getClientsLoading,
      // isSaving: getClientsLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      selectedClientNavigation === "active_client" && (
        <MenuItem
          key={0}
          onClick={() => {
            handleOpenEditClient(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit Client
        </MenuItem>
      ),
      selectedClientNavigation === "active_client" && (
        <MenuItem
          key={1}
          onClick={() => {
            handleOpenDisableClient(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Disable Client
        </MenuItem>
      ),

      selectedClientNavigation !== "active_client" && (
        <MenuItem
          key={1}
          onClick={() => {
            handleOpenEnableClient(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Enable Client
        </MenuItem>
      ),
    ],
  });

  useEffect(() => {
    handleClientNavigation(selectedClientNavigation);
  }, []);

  const handleOpenCreateClient = () => {
    setOpenCreateClientDialog(true);
  };
  const handleCloseCreateClient = (message: string) => {
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setOpenCreateClientDialog(false);
  };

  const handleOpenEditClient = (rowData: any) => {
    setEditClientRowData(rowData);
    setOpenEditClientDialog(true);
  };
  const handleCloseEditClient = (message: string) => {
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setOpenEditClientDialog(false);
  };

  const handleOpenDisableClient = (rowData: any) => {
    setDisableClientRowData(rowData);
    setOpenDisableClientDialog(true);
  };
  const handleCloseDisableClient = (message: string) => {
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setDisableClientRowData(null);
    setOpenDisableClientDialog(false);
  };

  const handleOpenEnableClient = (rowData: any) => {
    setEnableClientRowData(rowData);
    setOpenEnableClientDialog(true);
  };
  const handleCloseEnableClient = (message: string) => {
    if (message !== "") {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setEnableClientRowData(null);
    setOpenEnableClientDialog(false);
  };

  return (
    <Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px 10px 20px",
        }}
      >
        <Typography variant="h4" sx={{ color: "#004680", fontWeight: "bold" }}>
          {"Client Management"}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 20px 10px 20px",
        }}
      >
        <Grid sx={{ justifyContent: "center", padding: "5px" }}>
          <Button
            onClick={() => handleOpenCreateClient()}
            variant="outlined"
            sx={{
              color: "white !important",
              fontWeight: "bold !important",
              padding: "0px 20px 0px 20px !important",
              backgroundColor: "#38B54A !important",
            }}
          >
            Add Client
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          padding: "0px 20px 20px 20px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#4C4C4C",
            borderTop: "5px solid white",
            height: "40px",
            paddingLeft: "10px",
          }}
        >
          <Button
            onClick={() => handleClientNavigation("active_client")}
            sx={{
              color:
                selectedClientNavigation === "active_client"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            Active clients
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: "white", backgroundColor: "white" }}
          />
          <Button
            onClick={() => handleClientNavigation("disabled_client")}
            sx={{
              color:
                selectedClientNavigation === "disabled_client"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            Disabled clients
          </Button>
        </Grid>
        <Grid container item xs={12} sx={{ padding: "20px 0px 20px 0px" }}>
          <TableTemplate tableData={clientTable} />
        </Grid>
      </Grid>
      <CreateClientDialog
        open={openCreateClientDialog}
        handleCreateClientClose={(meassge: string) =>
          handleCloseCreateClient(meassge)
        }
      />
      <EditClientDialog
        open={openEditClientDialog}
        handleEditClientClose={(meassge: string) =>
          handleCloseEditClient(meassge)
        }
        editClientData={editClientRowData}
      />
      <DisableClientDialog
        open={openDisableClientDialog}
        handleDisableClientClose={(meassge: string) =>
          handleCloseDisableClient(meassge)
        }
        disableClientData={disableClientRowData}
      />
      <EnableClientDialog
        open={openEnableClientDialog}
        handleEnableClientClose={(meassge: string) =>
          handleCloseEnableClient(meassge)
        }
        enableClientData={enableClientRowData}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={10000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
    </Grid>
  );
};
export default ClientManagement;
