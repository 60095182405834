import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export const backendURL =
  process.env.NODE_ENV !== "production" ? "http://localhost:5000" : "";

export const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "*/*",
  },
};

export const GetArray = (object: any) => {
  if (object && Object?.keys(object).length > 0) {
    return Object?.keys(object)?.reduce((r: any, k: any) => {
      if (Array.isArray(object[k])) {
        object[k].forEach((a: any, i: any) => {
          r[i] = r[i] || {};
          r[i][k] = a;
        });
      }
      return r;
    }, []);
  } else return [];
};

export function GetObject(array: any[]) {
  return array?.reduce((r, o, i) => {
    if (Array.isArray(array)) {
      Object.keys(o).forEach((k) => {
        r[k] = r[k] || [];
        r[k][i] = o[k];
      });
    } else {
      r = array[o];
    }
    return r;
  }, {});
}

export function ConvertToObjectList(items: any[]) {
  return items.map((e) => {
    return {
      label: e,
      value: e,
    };
  });
}

export function WithCommas(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function IsEmpty(obj: {}) {
  return Object.keys(obj).length === 0;
}

export function TableIsEmpty(table: any) {
  return table.length === 0;
}

export const SaveFileBlob = (buffer: any, filename: any) => {
  import("file-saver").then(({ default: FileSaver }) => {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      filename + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  });
};

export const compareTwoObjectArray = (a: any, b: any, key: string) => {
  return (
    a.length === b.length &&
    a.every((element_1: any) =>
      b.some((element_2: any) =>
        Object.keys(element_1).every((key) => element_1[key] === element_2[key])
      )
    )
  );
};

export const compareTwoStringArray = (a: any, b: any) => {
  return (
    a.length === b.length &&
    a.every((element_1: any) =>
      b.some((element_2: any) => element_1 === element_2)
    )
  );
};

export const exportToExcel = (data: any, filename: any) => {
  const exportData: any[] = [];
  data.forEach((element: any) => {
    exportData.push(element.original);
  });

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${filename}.xlsx`);
};

export const exportToExcelOther = (data: any, filename: any) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${filename}.xlsx`);
};
