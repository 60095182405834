import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { tokenInterceptor } from "../../utils/authMidleware";
import { backendURL, config, Backend_Urls } from "../../utils/constants";

const getClientsInfo = createAsyncThunk(
  "portalManagement/getClientsInfo",
  async ({}: {}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.account_management}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getClientsPrograms = createAsyncThunk(
  "portalManagement/getClientsPrograms",
  async ({ client_index }: { client_index: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.client_programs}/${client_index}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getClientsSalesRep = createAsyncThunk(
  "portalManagement/getClientsSalesRep",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.client_salesmen}/${customer_id}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getBranchesDepartments = createAsyncThunk(
  "portalManagement/getBranchesDepartments",
  async ({ payload }: { payload: any }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.branches_and_departments}/${payload.customer_id}`,
        payload,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getLastUpdated = createAsyncThunk(
  "portalManagement/getLastUpdated",
  async ({ payload }: { payload: any }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${backendURL}${Backend_Urls.last_updated}/${payload}`,
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGrowthScore = createAsyncThunk(
  "portalManagement/getGrowthScore",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.growth_score}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRetention = createAsyncThunk(
  "portalManagement/getRetention",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.retention}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAtRisk = createAsyncThunk(
  "portalManagement/getAtRisk",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.atrisk}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGoogleReviews = createAsyncThunk(
  "portalManagement/getGoogleReviews",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.g_reviews}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getNpsScore = createAsyncThunk(
  "portalManagement/getNpsScore",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.nps_score}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getNpsSurvey = createAsyncThunk(
  "portalManagement/getNpsSurvey",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.nps_survey}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getIndustries = createAsyncThunk(
  "portalManagement/getIndustries",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.industries}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPurchase = createAsyncThunk(
  "portalManagement/getPurchase",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.purchase}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRetentionLeads = createAsyncThunk(
  "portalManagement/getRetentionLeads",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.retention_leads}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getProspectVerified = createAsyncThunk(
  "portalManagement/getProspectVerified",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.prospect_verified}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getEmailReports = createAsyncThunk(
  "portalManagement/getEmailReports",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.get_email_reports}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGrowthRate = createAsyncThunk(
  "portalManagement/getGrowthRate",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.growth_rate}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGrowthRateDetail = createAsyncThunk(
  "portalManagement/getGrowthRateDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.growth_rate_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGrowthRateBenchmark = createAsyncThunk(
  "portalManagement/getGrowthRateBenchmark",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.growth_rate_benchmark}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getForecastDetail = createAsyncThunk(
  "portalManagement/getForecastDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.forecast_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getAtriskDetail = createAsyncThunk(
  "portalManagement/getAtriskDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.atrisk_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getWgs = createAsyncThunk(
  "portalManagement/wgs",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.wgs}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getWgsDetail = createAsyncThunk(
  "portalManagement/getWgsDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.wgs_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGgdRating = createAsyncThunk(
  "portalManagement/getGgdRating",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.ggd_rating}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGgdRatingDetail = createAsyncThunk(
  "portalManagement/getGgdRatingDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.ggd_rating_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getNetPromoter = createAsyncThunk(
  "portalManagement/getNetPromoter",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.net_promoter}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getCustomerSurvey = createAsyncThunk(
  "portalManagement/getCustomerSurvey",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.customer_survey}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRetentionRate = createAsyncThunk(
  "portalManagement/getRetentionRate",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.retention_rate}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getLastPurchaseCustomerLeads = createAsyncThunk(
  "portalManagement/getLastPurchaseCustomerLeads",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.last_purchase_cust_leads}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getLastPurchaseDetail = createAsyncThunk(
  "portalManagement/getLastPurchaseDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.last_purchase_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getProspectLeadsReports = createAsyncThunk(
  "portalManagement/getProspectLeadsReports",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.prospect_leads_reports}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getProspectLeadsReportsDetail = createAsyncThunk(
  "portalManagement/getProspectLeadsReportsDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.prospect_leads_reports_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getGainLostAccounts = createAsyncThunk(
  "portalManagement/getGainLostAccounts",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.gain_and_lost_accounts}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getIndustryByRevenue = createAsyncThunk(
  "portalManagement/getIndustryByRevenue",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.industry_by_revenue}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getIndustryByRevenueDetail = createAsyncThunk(
  "portalManagement/getIndustryByRevenueDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.industry_by_revenue_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getPurchaseDistribution = createAsyncThunk(
  "portalManagement/getPurchaseDistribution",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.purchase_distribution}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getDistributionTransactionRevenue = createAsyncThunk(
  "portalManagement/getDistributionTransactionRevenue",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.distribution_transaction_revenue}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getCustomerSurveyDetail = createAsyncThunk(
  "portalManagement/getCustomerSurveyDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.customer_survey_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getProductAnalysisDetail = createAsyncThunk(
  "portalManagement/getProductAnalysisDetail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.product_analysis_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRankByEmail = createAsyncThunk(
  "portalManagement/getRankByEmail",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.rank_by_email}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRankByRetention = createAsyncThunk(
  "portalManagement/getRankByRetention",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.rank_by_retention}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRankByStableAccount = createAsyncThunk(
  "portalManagement/getRankByStableAccount",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.rank_by_stable_account}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRankByWgs = createAsyncThunk(
  "portalManagement/getRankByWgs",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.rank_by_wgs}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getRankByWgsDetails = createAsyncThunk(
  "portalManagement/getRankByWgsDetails",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.rank_by_wgs_detail}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getMarketByCustomer = createAsyncThunk(
  "portalManagement/getMarketByCustomer",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.market_by_customer}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getMarketOverview = createAsyncThunk(
  "portalManagement/getMarketOverview",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.market_overview}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getProductByCustomerCount = createAsyncThunk(
  "portalManagement/getProductByCustomerCount",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.product_by_cust_count}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getProductByRevenue = createAsyncThunk(
  "portalManagement/getProductByRevenue",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.product_by_revenue}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getForecastRevenuePlan = createAsyncThunk(
  "portalManagement/getForecastRevenuePlan",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.forecast_revenue_plan}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getActualMargin = createAsyncThunk(
  "portalManagement/getActualMargin",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.actual_margin_dict}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getBranchTransaction = createAsyncThunk(
  "portalManagement/getBranchTransaction",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.branch_transactions}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getTrainingCourse = createAsyncThunk(
  "portalManagement/getTrainingCourse",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.employee_course}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getTrainingModule = createAsyncThunk(
  "portalManagement/getTrainingModule",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.employee_module}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getTrainingModuleSummary = createAsyncThunk(
  "portalManagement/getTrainingModuleSummary",
  async ({ customer_id }: { customer_id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.employee_module_summary}/${customer_id}`,
        { client_id: customer_id },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const getEquipmentAnalytic = createAsyncThunk(
  "portalManagement/getEquipmentAnalytic",
  async (
    {
      customer_id,
      portal_account,
      user,
    }: { customer_id: string; portal_account: string; user: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        `${backendURL}${Backend_Urls.data_url}`,
        {
          client_id: customer_id.toString(),
          portal_account: portal_account.toString(),
          user: user,
        },
        tokenInterceptor()
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.msg) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export {
  getClientsInfo,
  getClientsPrograms,
  getClientsSalesRep,
  getBranchesDepartments,
  getLastUpdated,
  getGrowthScore,
  getRetention,
  getAtRisk,
  getGoogleReviews,
  getNpsScore,
  getNpsSurvey,
  getIndustries,
  getPurchase,
  getRetentionLeads,
  getProspectVerified,
  getEmailReports,
  getGrowthRate,
  getGrowthRateDetail,
  getGrowthRateBenchmark,
  getForecastDetail,
  getAtriskDetail,
  getWgs,
  getWgsDetail,
  getGgdRating,
  getGgdRatingDetail,
  getNetPromoter,
  getCustomerSurvey,
  getRetentionRate,
  getLastPurchaseCustomerLeads,
  getLastPurchaseDetail,
  getProspectLeadsReports,
  getProspectLeadsReportsDetail,
  getGainLostAccounts,
  getIndustryByRevenue,
  getIndustryByRevenueDetail,
  getPurchaseDistribution,
  getDistributionTransactionRevenue,
  getCustomerSurveyDetail,
  getProductAnalysisDetail,
  getRankByEmail,
  getRankByRetention,
  getRankByStableAccount,
  getRankByWgs,
  getRankByWgsDetails,
  getMarketByCustomer,
  getMarketOverview,
  getProductByCustomerCount,
  getProductByRevenue,
  getForecastRevenuePlan,
  getActualMargin,
  getBranchTransaction,
  getTrainingCourse,
  getTrainingModuleSummary,
  getTrainingModule,
  getEquipmentAnalytic,
};
